/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-const */
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { LanguageService } from 'language';
import { SPRINKLR_APP_ID } from './sprinklr-app-id.token';
import { SprChat, SprChatEventNames } from './sprinklr.types';

function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class NgxSprinklrWebwidgetService {
  private readonly window = getWindow();
  public chat: SprChat = (...rest: any) => {
    console.log('fake chat', rest);
    this.init();
    this.chat(rest);
  };
  private appId = inject(SPRINKLR_APP_ID, { optional: true });
  private lang = inject(LanguageService);
  events$ = new BehaviorSubject<SprChatEventNames>(null);

  public init() {
    this.loadScript();
    this.chat = this.window.sprChat;

    this.chat('subscribeToUpdate', {
      topic: 'eventTriggered',
      subscriber: params => {
        this.events$.next(params.response.data.eventType);
      },
    });

    this.events$.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.chat = this.window.sprChat;
    });

    this.lang.onLangChange.subscribe(lang => {
      this.chat('updateLocale', lang);
    });
  }

  private loadScript() {
    const window = this.window;

    if (window.sprChat) return; // google tag manager loads this script as well, so we should avoid double loading
    window.sprChatSettings = { appId: this.appId, skin: 'MODERN', locale: this.lang.current };

    let t = window,
      e = t.sprChat,
      a = e && !!e.loaded,
      n = document,
      r: any = function () {
        r.m(arguments);
      };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (r.q = []),
      (r.m = function (t: any) {
        r.q.push(t);
      }),
      (t.sprChat = a ? e : r);
    let o = function () {
      let e = n.createElement('script');
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (e.type = 'text/javascript'),
        (e.async = !0),
        (e.src = 'https://prod18-live-chat.sprinklr.com/api/livechat/handshake/widget/' + t.sprChatSettings.appId);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (e.onerror = function () {
        t.sprChat.loaded = !1;
      }),
        (e.onload = function () {
          t.sprChat.loaded = !0;
        });
      let a = n.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(e, a);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    'function' == typeof e
      ? a
        ? e('update', t.sprChatSettings)
        : o()
      : 'loading' !== n.readyState
        ? o()
        : n.addEventListener('DOMContentLoaded', o);
  }
}
