.primary-color {
  fill: var(--color-gray-2);
}

.secondary-color {
  fill: var(--basic-white);
}

.active {
  .primary-color {
    fill: var(--color-primary);
  }
}

// Fix for Angular Hydration + ngx-dynamic-hooks bug that was displaying the tooltip content outside of the tooltip after loading
dynamic-component-contentslot {
  display: none;
}
